<template>
  <div class="enterpriseDetails">
    <Header/>
    <div class="enterpriseHeader" style="margin-top: 48px">
      <div class="headerWrap" style="align-items: center">
        <div class="enterprise">
          <img style="border-radius:6.67px" :src="info?.logo?.url">
          <div class="rights">
            <div class="name">{{ info?.name }}</div>
            <div style="display: flex">
              <div class="desc" v-for="item in info?.tags" :key="item">{{ item }}<span>·</span></div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="top" >
            <div class="item" v-if="info?.timeTable">
              <img src="@/assets/images/timer.png">
              <span>上午{{ info?.timeTable?.[0] }} - 下午{{ info?.timeTable?.[1] }}</span>
            </div>
            <div class="item" v-if="info?.rest">
              <img src="https://static.dasdt.cn/images/experience2.png">
              <span>{{ info?.rest }}</span>
            </div>
          </div>
          <!--                  <div class="welfare">-->
          <!--                    <div class="item" v-for="item in info?.tags" :key="item">{{item}}</div>-->
          <!--                  </div>-->
        </div>
      </div>
    </div>
    <div class="contentWrap">
      <div style="padding: 0 16px 16px 16px">
        <el-tabs v-model="activeName">
          <el-tab-pane label="公司信息" name="1">
          </el-tab-pane>
          <el-tab-pane :label="`在招职位(${info?.jobList.length})`" name="2">
          </el-tab-pane>
        </el-tabs>
        <div v-show="activeName === '1'">
          <companyInfo :info="info"/>
        </div>
        <div v-if="activeName === '2'">
          <recommendedPositions :info="info"/>
        </div>
      </div>

    </div>
    <Footer/>
    <floatRight/>
    <logins v-model="isShow2"/>

  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import companyInfo from "@/views/enterpriseDetails/companyInfo.vue";
import recommendedPositions from "@/views/enterpriseDetails/recruitmentPositions.vue"
import floatRight from "@/components/floatRight/vue.vue";
import Footer from "@/components/footer/index.vue";
import {findByAesId} from "@/api/position";
import logins from "@/components/login/index.vue";

export default {
  components: {logins, Footer, floatRight, Header, companyInfo, recommendedPositions},
  data() {
    return {
      activeName: '1',
      info: {}
    }
  },
  created() {
    scrollTo(0, 0)
    this.findByAesId()
  },
  computed: {
    isShow2() {
      return this.$store.getters.showLogin
    },
  },
  methods: {
    async findByAesId() {
      const res = await findByAesId(this.$route.params.id)
      res.data[0].jobList.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
        if (res.data[0].customFirmTag) {
          res.data[0].firmTagList = [...res.data[0].firmTagList, ...res.data[0].customFirmTag]
        }
        console.log('item.tagList', item.tagList)

        if(item.tagList&&item.tagList.length>3) {
          item.tagList = item.tagList.slice(0, 3)
        }
        console.log('item.tags', item.tags)
        if(item.tags&&item.tags.length>3) {
          item.tags = item.tags.slice(0, 3)
        }
      })

      this.info = res.data[0]
      var year = this.info?.foundDate.substring(0, 4);
      var month = this.info?.foundDate.substring(4, 6);
      var day = this.info?.foundDate.substring(6, 8);
      this.info.foundDate = year + "年" + month + "月" + day + "日";
      console.log(this.info?.foundDate)
      console.log(res.data)
    }
  }
}
</script>
<style lang="scss">
.enterpriseDetails {
  overflow: hidden;
  background: #F5F5F6;

  .contentWrap {
    position: relative;
    z-index: 10;
    width: 1200px;
    margin: 24px auto;
    border-radius: 8px;
    background: #ffffff;
    //cursor: pointer;

    .el-tabs__nav-wrap::after {
      height: 1px !important;
      background-color: rgba($color: #1F1F1F, $alpha: 0.1) !important;
    }

    .el-tabs__active-bar {
      background-color: #185BE0 !important;

    }

    .el-tabs__item.is-active {
      color: #185BE0 !important;
    }

    .el-tabs__item {
      &:hover {
        color: #185BE0 !important;

      }
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
      padding-left: 16px;
    }

  }

  .enterpriseHeader {
    position: relative;
    z-index: 10;
    background: #fff;
    height: 122px;
    display: flex;
    align-items: center;

    .headerWrap {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .right {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .welfare {
          display: flex;
          align-items: center;
          gap: 6px;

          .item {
            padding: 8px 10px;
            border-radius: 4px;
            background: rgba($color: #FFFFFF, $alpha: 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .top {
          display: flex;
          align-items: center;
          gap: 18px;

          .item {
            display: flex;
            align-items: center;
            gap: 2px;

            img {
              width: 16px;
              height: 16px;
            }

            span {
              color: #555555;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
      }

      .enterprise {
        display: flex;
        align-items: center;
        gap: 16px;

        img {
          width: 68px;
          height: 68px;
        }

        .rights {
          display: flex;
          flex-direction: column;
          gap: 16px;

          .name {
            color: #141414;
            font-size: 30px;
            font-weight: 600;
          }

          .desc {
            color: #808080;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            display: flex;

            span {
              display: none;
            }
          }

          .desc:not(:last-child) {
            span {
              display: block !important;
            }
          }
        }
      }
    }
  }
}
</style>
